.footer {
    width: 100%;
    height: 200px;
    background: #FBF9F9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 200px;
  }
  
  .socialMedia svg {
    margin: 20px;
    cursor: pointer;
    font-size: 20px;
    margin: 5px;
    color: #303D37;
}
  
.footer p {
  color: #353535;
  font-weight: 600;
}

.footer a {
  text-decoration: none;
  color: #303D37;
  font-weight: 600;
}
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }